class ProductService {
	constructor() {
		this.productObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredProductObjects =
			this._getRegisteredProductObjects.bind(this);
	}

	/**
	 * register - allows pageobjects to register with page-service
	 * @param {Function} getDataCallback_ - callback function of page object
	 * @param {boolean} priority_ - importance of page object
	 * @returns {void} returns nothing
	 */
	register(getDataCallback_, priority_) {
		let position =
			this.productObjectCallbacks.length === 0
				? 1
				: this.productObjectCallbacks.length;

		if (priority_) {
			this.productObjectCallbacks[0] = getDataCallback_;
		} else {
			this.productObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows objects to unregister from product-service
	 * @param {Function} getDataCallback_ - callback function of product object
	 * @returns {void} returns nothing
	 */
	unregister(getDataCallback_) {
		let index = this.productObjectCallbacks.indexOf(getDataCallback_);
		this.productObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @returns {Object} returns the product object as a promise
	 */
	async getData() {
		let productArrays = await this._getRegisteredProductObjects();

		let mergedProductArrays = productArrays.reduce(
			(accumulator, currentValue) => accumulator.concat(currentValue),
			[],
		);

		return mergedProductArrays;
	}

	/**
	 * _getRegisteredProductObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of pageobjects
	 */
	async _getRegisteredProductObjects() {
		let productObjects = [];

		for (let callback in this.productObjectCallbacks) {
			if (callback && this.productObjectCallbacks[callback]) {
				let tempObject = await this.productObjectCallbacks[callback]();
				productObjects.push(tempObject);
			}
		}

		return productObjects;
	}
}

const productService = new ProductService();
export { productService, ProductService };
