import { utils } from '../../utils';

class SearchService {
	constructor() {
		this.searchObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredSearchObjects =
			this._getRegisteredSearchObjects.bind(this);
	}

	/**
	 * register - allows search objects to register with search service
	 * @param {Function} getDataCallback_ - callback function of registering search object
	 * @param {boolean} priority_ - priority of search object
	 * @returns {void}
	 */
	register(getDataCallback_, priority_) {
		const position =
			this.searchObjectCallbacks.length === 0
				? 1
				: this.searchObjectCallbacks.length;

		if (priority_) {
			this.searchObjectCallbacks[0] = getDataCallback_;
		} else {
			this.searchObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows objects to unregister from search service
	 * @param {Function} getDataCallback_ - callback function of search object
	 * @returns {void}
	 */
	unregister(getDataCallback_) {
		const index = this.searchObjectCallbacks.indexOf(getDataCallback_);
		this.searchObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @returns {Object} returns the search object as a promise
	 */
	async getData() {
		const searchObjects = await this._getRegisteredSearchObjects();
		const mergedSearchObject = utils.mergeObjects(...searchObjects);

		return mergedSearchObject;
	}

	/**
	 * _getRegisteredSearchObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of search objects
	 */
	async _getRegisteredSearchObjects() {
		let searchObjects = [];

		for (const callback in this.searchObjectCallbacks) {
			if (callback && this.searchObjectCallbacks[callback]) {
				const tempObject = await this.searchObjectCallbacks[callback]();
				searchObjects.push(tempObject);
			}
		}

		return searchObjects;
	}
}

const searchService = new SearchService();
export { searchService, SearchService };
