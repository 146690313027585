import { stateEventService } from './state-event-service';

class StateEventObjectDefault {
	/**
	 * registers to StateEventService
	 * @constructor singleton
	 */
	constructor() {
		if (StateEventObjectDefault._instance) {
			return StateEventObjectDefault._instance;
		}

		stateEventService.register(StateEventObjectDefault.getData, true);

		StateEventObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Promise} promise - returns promise for stateEventObject
	 */
	static async getData() {
		let eventObject = {
			attributes: {
				clickID: '',
				componentName: '',
				currentURL: window.location.href,
				elementName: '',
				label: '',
				pos: '',
				targetURL: '',
				value: '',
			},
			eventInfo: {
				eventAction: 'page_load',
				eventName: 'generic',
			},
		};

		return eventObject;
	}
}

/**
 * Singleton instance
 * @type {StateEventObjectDefault}
 * @private
 * @static
 */

const stateEventObjectDefault = new StateEventObjectDefault();

export { stateEventObjectDefault, StateEventObjectDefault };
