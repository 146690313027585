import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectLayerOpen {
	constructor() {
		if (ClickEventObjectLayerOpen._instance) {
			return ClickEventObjectLayerOpen._instance;
		}

		this.getData = this.getData.bind(this);
		this.layerOpenSelectors = [];

		clickEventService.register(this.getData);

		ClickEventObjectLayerOpen._instance = this;
	}

	/**
	 * register a selector to be recognized as layer open
	 * @param {string} eventOpenSelector_ - the selector to register as a layer open
	 * @returns {void}
	 */
	registerLayerOpenSelector(eventOpenSelector_) {
		this.layerOpenSelectors.push(eventOpenSelector_);
	}

	/**
	 * getData - collects the tracking data
	 * @param {JSON} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	async getData(eventData_) {
		const eventTarget = ClickEventHelperDom.getTarget(eventData_);

		if (!this._isLayerOpenLink(eventTarget)) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: 'layer_open_click',
			},
		};
	}

	/**
	 * checks whether an event target is a layer open link
	 * @param {DOMTokenList} eventTarget_ - an html element
	 * @returns {boolean} - true if it is a layer open link / false if not
	 */
	_isLayerOpenLink(eventTarget_) {
		for (const layerOpenSelector of this.layerOpenSelectors) {
			if (
				eventTarget_ &&
				eventTarget_.closest(layerOpenSelector) === eventTarget_
			) {
				return true;
			}
		}
		return false;
	}
}

const clickEventObjectLayerOpen = new ClickEventObjectLayerOpen();
export { clickEventObjectLayerOpen, ClickEventObjectLayerOpen };
