import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectExternal {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectExternal._instance) {
			return ClickEventObjectExternal._instance;
		}

		clickEventService.register(ClickEventObjectExternal.getData);

		ClickEventObjectExternal._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getData(eventData_) {
		const url = ClickEventHelperDom.getTargetUrl(eventData_);

		if (!ClickEventObjectExternal._isExternalLink(url)) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: 'exit_link',
			},
		};
	}

	/**
	 * check whether the target url is an external link or not
	 * @param {String} url_ - target url
	 * @returns {boolean} true, if external, false else
	 */
	static _isExternalLink(url_) {
		if (url_.indexOf('http') === -1 && url_.indexOf('//') === -1) {
			return false;
		}

		if (this._isLinkOfSameDomain(url_, window.location)) {
			return false;
		}

		return true;
	}

	/**
	 * check whether the target url is within the current domain
	 * @param {String} url_ - target url
	 * @param {String} origin_ - window.location
	 * @returns {boolean} true, if same domain, false if not
	 */
	static _isLinkOfSameDomain(url_, origin_) {
		let pathParts = url_.split('/');
		let hostname = origin_.hostname;
		let hostWithPort = origin_.hostname + ':' + origin_.port;

		return pathParts[2] === hostname || pathParts[2] === hostWithPort;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectExternal}
 * @private
 * @static
 */

const clickEventObjectExternal = new ClickEventObjectExternal();

export { clickEventObjectExternal, ClickEventObjectExternal };
