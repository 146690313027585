class StateEventService {
	constructor() {
		this.eventObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredEventObjects =
			this._getRegisteredEventObjects.bind(this);
	}

	/**
	 * register - allows pageobjects to register with page-service
	 * @param {Function} getDataCallback_ - callback function of page object
	 * @param {boolean} priority_ - importance of page object
	 * @returns {void} returns nothing
	 */
	register(getDataCallback_, priority_) {
		let position =
			this.eventObjectCallbacks.length === 0
				? 1
				: this.eventObjectCallbacks.length;

		if (priority_) {
			this.eventObjectCallbacks[0] = getDataCallback_;
		} else {
			this.eventObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows pageobjects to unregister from page-service
	 * @param {Function} getDataCallback_ - callback function of page object
	 * @returns {void} returns nothing
	 */
	unregister(getDataCallback_) {
		let index = this.eventObjectCallbacks.indexOf(getDataCallback_);
		this.eventObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @param {String} eventType_ - event typ
	 * @returns {Object} returns the page object as a promise
	 */
	async getData(eventType_) {
		let eventObjects = await this._getRegisteredEventObjects(eventType_);

		let mergedObject = this._mergeObjects(...eventObjects);

		return mergedObject;
	}

	/**
	 * _getRegisteredEventObjects - collects the tracking data of all registered objects
	 * @param {String} eventType_ - event typ
	 * @returns {Array} returns array of objects
	 */
	async _getRegisteredEventObjects(eventType_) {
		let eventObjects = [];

		for (let callback in this.eventObjectCallbacks) {
			if (callback && this.eventObjectCallbacks[callback]) {
				let dataEventObject = await this.eventObjectCallbacks[callback](
					eventType_,
				);
				eventObjects.push(dataEventObject);
			}
		}

		return eventObjects;
	}

	/**
	 * _mergeObjects - Performs a deep merge of objects and returns new object. Does not modify
	 * objects (immutable)
	 * @param {...object} objects - Objects to merge
	 * @returns {object} New object with merged key/values
	 */
	_mergeObjects(...objects) {
		const isObject = (obj) => obj && typeof obj === 'object';

		return objects.reduce((previousObject, currentObject) => {
			Object.keys(currentObject).forEach((key) => {
				const previousValue = previousObject[key];
				const currentValue = currentObject[key];

				if (
					Array.isArray(previousValue) &&
					Array.isArray(currentValue)
				) {
					previousObject[key] = currentValue;
				} else if (isObject(previousValue) && isObject(currentValue)) {
					previousObject[key] = this._mergeObjects(
						previousValue,
						currentValue,
					);
				} else {
					previousObject[key] = currentValue;
				}
			});

			return previousObject;
		}, {});
	}
}

const stateEventService = new StateEventService();
export { stateEventService, StateEventService };
