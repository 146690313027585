import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectEmail {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectEmail._instance) {
			return ClickEventObjectEmail._instance;
		}

		clickEventService.register(ClickEventObjectEmail.getData);

		ClickEventObjectEmail._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @param {JSON} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getData(eventData_) {
		const href = ClickEventHelperDom.getTargetUrl(eventData_);

		if (!ClickEventObjectEmail._isEmailLink(href)) {
			return {};
		}

		return {
			attributes: {
				targetURL: ClickEventObjectEmail._getEmailAddress(href),
				value: '',
			},
			eventInfo: {
				eventAction: 'email',
			},
		};
	}

	/**
	 * checks whether an href is an email link
	 * @param {String} href_ - an href attribute value
	 * @returns {boolean} - true if it is an email link / false if not
	 */
	static _isEmailLink(href_) {
		return href_ ? href_.indexOf('mailto:') === 0 : false;
	}

	/**
	 * get the email address from an href attribute
	 * @param {string} href_ - am href attribute value
	 * @returns {string} - email address or empty string if href does not contain an email address
	 */
	static _getEmailAddress(href_) {
		const hrefParts = href_ ? href_.split(':') : [];

		if (hrefParts.length < 1 || hrefParts[0] !== 'mailto') {
			return '';
		}

		return hrefParts[1];
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectEmail}
 * @private
 * @static
 */

const clickEventObjectEmail = new ClickEventObjectEmail();

export { clickEventObjectEmail, ClickEventObjectEmail };
