import { clickEventService } from './click-event-service';

class ClickEventObjectTeaser {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectTeaser._instance) {
			return ClickEventObjectTeaser._instance;
		}

		clickEventService.register(ClickEventObjectTeaser.getData);

		ClickEventObjectTeaser._instance = this;
	}

	/**
	 * Returns event info "teaser click", if the clicked link is placed within a teaser module
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} - the promise that resolves with additional teaser tracking data
	 */
	static async getData(eventData_) {
		if (ClickEventObjectTeaser._isLinkWithinTeaser(eventData_)) {
			return {
				attributes: {
					value: ClickEventObjectTeaser._getTeaserTitle(eventData_),
				},
				eventInfo: {
					eventName: 'teaser click',
				},
			};
		}

		return {};
	}

	/**
	 * Returns true if the clicked link is placed within a teaser module. Else: false
	 * @param {Object} eventData_ - event data
	 * @returns {boolean} indicates whether the clicked link is placed within a teaser module
	 * @private
	 */
	static _isLinkWithinTeaser(eventData_) {
		const teaserElement = eventData_.currentTarget.closest(
			'.nm-j-teaser-module',
		);

		return teaserElement !== null;
	}

	/**
	 * Returns the text of the clicked element = teaser title
	 * @param {Object} eventData_ - event data
	 * @returns {string} teaser title
	 * @private
	 */
	static _getTeaserTitle(eventData_) {
		const clickedElement = eventData_.currentTarget;

		let title = clickedElement.innerText.trim();

		const teaserElement = eventData_.currentTarget.closest(
			'.nm-j-teaser-module',
		);

		if (
			teaserElement &&
			teaserElement.querySelector('.nm-j-teaser-title')
		) {
			title = teaserElement
				.querySelector('.nm-j-teaser-title')
				.innerText.trim();
		}

		return title;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectTeaser}
 * @private
 * @static
 */

const clickEventObjectTeaser = new ClickEventObjectTeaser();

export { clickEventObjectTeaser, ClickEventObjectTeaser };
