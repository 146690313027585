class DealerService {
	constructor() {
		this.dealerObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredDealerObjects =
			this._getRegisteredDealerObjects.bind(this);
	}

	/**
	 * register - allows dealer objects to register with dealer-service
	 * @param {Function} getDataCallback_ - callback function of dealer object
	 * @param {boolean} priority_ - importance of dealer object
	 * @returns {void} returns nothing
	 */
	register(getDataCallback_, priority_) {
		let position =
			this.dealerObjectCallbacks.length === 0
				? 1
				: this.dealerObjectCallbacks.length;

		if (priority_) {
			this.dealerObjectCallbacks[0] = getDataCallback_;
		} else {
			this.dealerObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows objects to unregister from dealer-service
	 * @param {Function} getDataCallback_ - callback function of dealer object
	 * @returns {void} returns nothing
	 */
	unregister(getDataCallback_) {
		let index = this.dealerObjectCallbacks.indexOf(getDataCallback_);
		this.dealerObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @returns {Object} returns the dealer object as a promise
	 */
	async getData() {
		let dealerArrays = await this._getRegisteredDealerObjects();

		let mergedDealerArrays = dealerArrays
			.filter((array) => Object.getOwnPropertyNames(array).length > 0)
			.reduce(
				(accumulator, currentValue) => accumulator.concat(currentValue),
				[],
			);

		return mergedDealerArrays;
	}

	/**
	 * _getRegisteredDealerObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of pageobjects
	 */
	async _getRegisteredDealerObjects() {
		let dealerObjects = [];

		for (let callback in this.dealerObjectCallbacks) {
			if (callback && this.dealerObjectCallbacks[callback]) {
				let dealerObject = await this.dealerObjectCallbacks[callback]();

				if (dealerObject !== {}) {
					dealerObjects.push(dealerObject);
				}
			}
		}

		return dealerObjects;
	}
}

const dealerService = new DealerService();
export { dealerService, DealerService };
