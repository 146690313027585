import { trackingAnalyticsApi } from '../../api/analyticsApi';
import { utils } from '../../utils';

class UserService {
	_trackingStoreName = 'audi:authStore';
	constructor() {
		if (UserService._instance) {
			return UserService._instance;
		}

		this.userObjectCallbacks = [];
		this.initialize();

		UserService._instance = this;
	}

	/**
	 * Adds callback_ to an internal array, that starts with index 1.
	 * If priority_ is true, callback_ is stored at index 0.
	 * So when it comes to merging the data returned by every registered callback, the priority one goes first.
	 * @param {Function} callback_ - adds data to the user tracking by returning them as Object or Array
	 * @param {boolean} priority_ - indicates the default callback
	 * @returns {void}
	 */
	register(callback_, priority_ = false) {
		if (typeof callback_ !== 'function') {
			throw new Error('bad parameter: callback_ is not of type function');
		}

		const index =
			this.userObjectCallbacks.length === 0
				? 1
				: this.userObjectCallbacks.length;

		if (priority_) {
			this.userObjectCallbacks[0] = callback_;
		} else {
			this.userObjectCallbacks[index] = callback_;
		}
	}

	/**
	 * The counterpart to register().
	 * @param {Function} callback_ - adds data to the user tracking by returning them as Object or Array
	 * @returns {void}
	 */
	unregister(callback_) {
		let index = this.userObjectCallbacks.indexOf(callback_);

		if (index !== -1) {
			this.userObjectCallbacks.splice(index, 1);
		}
	}

	/**
	 * Collect the user tracking data from callbacks
	 * @returns {Promise<Array>} - Promise that evaluates to user tracking data
	 */
	async getData() {
		const userObjects = await this._getRegisteredUserObjects();
		const mergedUserObject = utils.mergeObjects(...userObjects);

		return mergedUserObject;
	}

	/**
	 * _getRegisteredCartObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of cart objects
	 */
	async _getRegisteredUserObjects() {
		let userObjects = [];

		for (const callback in this.userObjectCallbacks) {
			if (callback && this.userObjectCallbacks[callback]) {
				const tempObject = await this.userObjectCallbacks[callback]();
				userObjects.push(tempObject);
			}
		}

		return userObjects;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._saveTrackingData = this._saveTrackingData.bind(this);
		this._getEventData = this._getEventData.bind(this);
		this._isWithinLifeTime = this._isWithinLifeTime.bind(this);
		this._updateTrackingSessionStore =
			this._updateTrackingSessionStore.bind(this);
		this.getTrackingSessionStore = this.getTrackingSessionStore.bind(this);
		this._subscribeToAuthenticationStore =
			this._subscribeToAuthenticationStore.bind(this);
		this._analyzeAuthenticationEvent =
			this._analyzeAuthenticationEvent.bind(this);
		this._subscribeToPageLoaded = this._subscribeToPageLoaded.bind(this);
		this._handleRefreshSession = this._handleRefreshSession.bind(this);
		this._getTrackingSessionStoreRaw =
			this._getTrackingSessionStoreRaw.bind(this);

		this._subscribeToAuthenticationStore();
		this._subscribeToPageLoaded();
	}

	/**
	 * update the storage object with a refreshed time stamp on configurator activities to avoid page timeouts
	 */
	_subscribeToPageLoaded() {
		document.addEventListener('PAGE_READY', this._handleRefreshSession);
		document.addEventListener('LAYER_LOADED', this._handleRefreshSession);
		document.addEventListener('LAYER_CLOSED', this._handleRefreshSession);
	}

	_handleRefreshSession() {
		const store = this._getTrackingSessionStoreRaw();
		if (store) {
			const originalTimestamp = store.timestamp;
			this._updateTrackingSessionStore(store.isAuthenticated);

			// if not is within lifetime and user is authenticated then we retrigger the event and update tracking data
			if (
				!this._isWithinLifeTime(originalTimestamp) &&
				store.isAuthenticated
			) {
				// get the tracking object and trigger tracking event
				let trackingObject = this._getEventData(store.isAuthenticated);
				this._saveTrackingData(trackingObject);
			}
		}
	}

	_subscribeToAuthenticationStore() {
		if (window && window.microkernel && window.microkernel.stateRegistry) {
			window.microkernel.stateRegistry.subscribeToStore(
				'dbadAuthServiceStore',
				(state) => {
					this._analyzeAuthenticationEvent(state);
				},
			);
		}
	}

	_analyzeAuthenticationEvent(state) {
		// get the deserizalized state and determine whether authentication changed or is expired.
		let sessionTracking = this.getTrackingSessionStore();

		let stateAuthenticated = state ? state.isAuthenticated : false;

		// update session store
		this._updateTrackingSessionStore(stateAuthenticated);

		// track only when tracking state is not set and user is authenticated or
		// something changes during the authentication process
		if (
			(!sessionTracking && stateAuthenticated) ||
			(sessionTracking &&
				stateAuthenticated != sessionTracking.isAuthenticated)
		) {
			// get the tracking object and trigger tracking event
			let trackingObject = this._getEventData(stateAuthenticated);
			this._saveTrackingData(trackingObject);
		}
	}

	_isWithinLifeTime(timestamp) {
		if (timestamp) {
			const isWithinLifeTime = Date.now() - timestamp < 30 * 60 * 1000;
			return isWithinLifeTime;
		}

		return false;
	}

	_updateTrackingSessionStore(isAuthenticated) {
		let objectToStore = JSON.stringify({
			isAuthenticated: isAuthenticated,
			timestamp: new Date().getTime(),
		});

		window.localStorage.setItem(this._trackingStoreName, objectToStore);
	}

	_getTrackingSessionStoreRaw() {
		const serializedStore =
			window.localStorage &&
			window.localStorage.getItem(this._trackingStoreName);

		if (serializedStore && serializedStore !== '') {
			const storeObject = JSON.parse(serializedStore);
			return storeObject;
		}

		return null;
	}

	getTrackingSessionStore() {
		const storeObject = this._getTrackingSessionStoreRaw();
		if (storeObject) {
			const result = this._isWithinLifeTime(storeObject.timestamp);
			if (result) {
				return storeObject;
			}
		}

		return null;
	}

	_getEventData(stateAuthenticated) {
		return {
			attributes: {
				clickID: '',
				componentName: '',
				currentUrl: `${window.location.hostname}${window.location.pathname}`,
				elementName: '',
				label: '',
				pos: '',
				targetUrl: '',
				value: '',
			},
			eventInfo: {
				eventAction: 'non_interaction',
				eventName: stateAuthenticated
					? 'user logged in'
					: 'user logged out',
			},
		};
	}

	/**
	 * saves page data via api call
	 * @param {any} state_ - tracking event
	 * @returns {void}
	 */
	async _saveTrackingData(trackingObject) {
		if (trackingObject) {
			// update the tracking object for user
			if (
				Object.prototype.hasOwnProperty.call(window.digitalData, 'page')
			) {
				// we will just updae the user object if the page tracking event is already sent over
				const trackingUserObject = await this.getData();
				trackingAnalyticsApi.storeObjectsInDigitalData({
					user: [trackingUserObject],
				});
			}

			// store the event for login
			trackingAnalyticsApi.storeEventsInDigitalData(trackingObject);
		}
	}
}

/**
 * Singleton instance
 * @type {UserService}
 * @private
 * @static
 */

const userService = new UserService();
export { userService, UserService };
