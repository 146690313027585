import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectDownload {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectDownload._instance) {
			return ClickEventObjectDownload._instance;
		}

		clickEventService.register(ClickEventObjectDownload.getData);

		ClickEventObjectDownload._instance = this;
	}

	static get DOWNLOAD_FILE_TYPES() {
		return [
			'doc',
			'docx',
			'eps',
			'xls',
			'ppt',
			'pptx',
			'pdf',
			'xlsx',
			'tab',
			'csv',
			'zip',
			'txt',
			'vsd',
			'vxd',
			'xml',
			'js',
			'css',
			'rar',
			'exe',
			'wma',
			'mov',
			'avi',
			'wmv',
			'mp3',
			'wav',
			'm4v',
			'ics',
		];
	}

	/**
	 * getData - collects the tracking data
	 * @param {JSON} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getData(eventData_) {
		const url = ClickEventHelperDom.getTargetUrl(eventData_);

		if (!ClickEventObjectDownload._isDownloadLink(url)) {
			return {};
		}

		let linkObject = {
			attributes: {
				value: ClickEventObjectDownload._getFileType(url).toUpperCase(),
			},
			eventInfo: {
				eventAction: 'download',
			},
		};

		return linkObject;
	}

	/**
	 * checks whether event object holds a download link
	 * @param {String} url_ url
	 * @returns {boolean} true if it is a download link / false if not
	 */
	static _isDownloadLink(url_) {
		let fileEnding = ClickEventObjectDownload._getFileType(url_);

		if (fileEnding === '') {
			return false;
		}

		return (
			ClickEventObjectDownload.DOWNLOAD_FILE_TYPES.indexOf(
				fileEnding.toLowerCase(),
			) !== -1
		);
	}

	/**
	 * get file name from a url
	 * @param {string} url_ - the hyperlink attribute value
	 * @returns {string} file type or empty string if url does not contain a filetype
	 */
	static _getFileType(url_) {
		let pathParts = url_.split('/');
		let fileName = pathParts[pathParts.length - 1];
		let filenameParts = fileName.split('.');

		if (filenameParts.length <= 1) {
			return '';
		}

		return filenameParts[filenameParts.length - 1];
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectDownload}
 * @private
 * @static
 */

const clickEventObjectDownload = new ClickEventObjectDownload();

export { clickEventObjectDownload, ClickEventObjectDownload };
