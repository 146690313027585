class Utils {
	/**
	 * mergeObjects - Performs a deep merge of objects_ and returns new object. Does not modify
	 * objects_ (immutable)
	 * @param {...object} objects_ - Objects to merge
	 * @returns {object} New object with merged key/values
	 */
	mergeObjects(...objects_) {
		const isObject = (obj) => obj && typeof obj === 'object';

		return objects_.reduce((previousObject, currentObject) => {
			Object.keys(currentObject).forEach((key) => {
				const previousValue = previousObject[key];
				const currentValue = currentObject[key];

				if (
					Array.isArray(previousValue) &&
					Array.isArray(currentValue)
				) {
					previousObject[key] = currentValue;
				} else if (isObject(previousValue) && isObject(currentValue)) {
					previousObject[key] = this.mergeObjects(
						previousValue,
						currentValue,
					);
				} else {
					previousObject[key] = currentValue;
				}
			});

			return previousObject;
		}, {});
	}

	/**
	 * Flattens collections_ and merges objects_ into each collection item
	 * @param {Array<Array<Object>>} collections_ - the array of collections to be flattened
	 * @param {Array<Object>} objects_ - the objects to be merged into each collection item
	 * @returns {Array<Object>} - the flattened collection
	 */
	flattenAndMergeObjects(collections_, objects_) {
		const flatArray = [];

		for (let collection of collections_) {
			for (let item of collection) {
				flatArray.push(this.mergeObjects(...objects_, item));
			}
		}

		return flatArray;
	}

	/**
	 * The limitation of "hh:mm:ss" encoding is that it can only represent 23 hours and 59 minutes.
	 * It'll overflow after 24 hours.
	 * @param {number} seconds floating point seconds
	 * @returns {string} mm:ss or hh:mm:ss
	 */
	static toMMSS(seconds) {
		if (seconds >= 3600) {
			return new Date(seconds * 1000).toISOString().substr(11, 8);
		}
		return new Date(seconds * 1000).toISOString().substr(14, 5);
	}

	/**
	 * Returns a function, that, as long as it continues to be invoked, will not
	 * be triggered. The function will be called after it stops being called for
	 * N milliseconds. If `immediate` is passed, trigger the function on the
	 * leading edge, instead of the trailing.
	 *
	 * @param {function} func - function to be debounced
	 * @param {number} wait - debounce time
	 * @param {boolean} [immediate] - execute the function on the leading edge
	 *
	 * @returns {function} - debounced function
	 *
	 * @see https://davidwalsh.name/javascript-debounce-function
	 */
	static debounce(func, wait, immediate) {
		let timeout;

		return function () {
			const context = this;
			const args = arguments;
			const later = function () {
				timeout = null;

				if (!immediate) {
					func.apply(context, args);
				}
			};
			const callNow = immediate && !timeout;

			clearTimeout(timeout);

			timeout = setTimeout(later, wait);

			if (callNow) {
				func.apply(context, args);
			}
		};
	}

	/**
	 * Returns a function, that, as long as it continues to be invoked, will be triggered
	 * with two parameters: the first is the one from the last invocation, the second one comes
	 * from the current invocation. It's built for comparing events.
	 * @param {Function} func - The function that should be invoked with the last and current parameters
	 * @returns {Function} - A function that is invoked with the last and current parameters
	 */
	static withLastParameter(func) {
		let lastArguments = null;

		return function () {
			if (lastArguments === null) {
				lastArguments = arguments;
				return;
			}

			func.call(this, ...lastArguments, ...arguments);

			lastArguments = arguments;
		};
	}
}

const utils = new Utils();
export { utils, Utils };
