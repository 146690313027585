class TrackingAnalyticsApi {
	static get REGISTERED_DIGITALDATA_OBJECTS() {
		return [
			'page',
			'component',
			'dealer',
			'product',
			'cart',
			'user',
			'search',
		];
	}

	/**
	 * initializes the Api
	 * @constructor singleton
	 */
	constructor() {
		if (TrackingAnalyticsApi._instance) {
			return TrackingAnalyticsApi._instance;
		}

		window.digitalData = window.digitalData || {};
		window.digitalData.event = window.digitalData.event || [];

		this.eventObjectsQueue = [];
		this.registeredPropertyObjectsQueue = {};

		TrackingAnalyticsApi._instance = this;
	}

	/**
	 * storeObjectsInDigitalData - writes data to digitalData object or queues it
	 * @param {Object} dataObjects_ - tracking data to store
	 * @return {void}returns nothing
	 */
	storeObjectsInDigitalData(dataObjects_) {
		// if page tracking already happened, just save the data
		// eslint-disable-next-line no-prototype-builtins
		if (window.digitalData.hasOwnProperty('page')) {
			this._writeDataToWindowObject(dataObjects_);
		}
		// otherwise check if incoming data contains page tracking data
		// eslint-disable-next-line no-prototype-builtins
		else if (dataObjects_.hasOwnProperty('page')) {
			this._writeDataToWindowObject(
				this._mergeRegisteredPropertyObjects(dataObjects_),
			);
		}
		// otherwise queue incoming data
		else {
			Object.keys(dataObjects_).forEach((registeredProperty) => {
				this.registeredPropertyObjectsQueue[registeredProperty] =
					dataObjects_[registeredProperty];
			});
		}
	}

	/**
	 * storeEventsInDigitalData - writes data to digitalData.event array
	 * @param {Object} eventObject_ - tracking data to store
	 * @return {void}returns nothing
	 */
	storeEventsInDigitalData(eventObject_) {
		if (Object.keys(eventObject_).length > 0) {
			if (
				window.digitalData.event.length === 0 &&
				this._getEventAction(eventObject_) !== 'page_load'
			) {
				this.eventObjectsQueue.push(eventObject_);
			} else {
				window.digitalData.event.push(eventObject_);

				this._processEventObjectsQueue();
			}
		}
	}

	/**
	 * _writeDataToWindowObject - writes data to window.digitalData
	 * @param {Object} dataObjects_ - tracking data to store
	 * @return {void}returns nothing
	 */
	_writeDataToWindowObject(dataObjects_) {
		TrackingAnalyticsApi.REGISTERED_DIGITALDATA_OBJECTS.forEach(
			(registeredProperty) => {
				if (dataObjects_[registeredProperty] !== undefined) {
					window.digitalData[registeredProperty] =
						dataObjects_[registeredProperty];
				}
			},
		);
	}

	/**
	 * merges all queued registeredProperty objects to the latest data objects
	 * @param {Object} dataObjects_ - tracking data to store
	 * @return {Object} merged data object
	 * @private
	 */
	_mergeRegisteredPropertyObjects(dataObjects_) {
		if (dataObjects_ && this.registeredPropertyObjectsQueue !== {}) {
			TrackingAnalyticsApi.REGISTERED_DIGITALDATA_OBJECTS.forEach(
				(registeredProperty) => {
					if (
						this.registeredPropertyObjectsQueue[
							registeredProperty
						] !== undefined
					) {
						dataObjects_[registeredProperty] =
							this.registeredPropertyObjectsQueue[
								registeredProperty
							];
					}
				},
			);
		}

		this.registeredPropertyObjectsQueue = {};

		return dataObjects_;
	}

	/**
	 * adds all queued event objects to the digital data object and clears queue
	 * @return {void}
	 * @private
	 */
	_processEventObjectsQueue() {
		const eventQueueLength = this.eventObjectsQueue.length;

		if (eventQueueLength > 0) {
			for (let i = 0; i < eventQueueLength; i += 1) {
				window.digitalData.event.push(this.eventObjectsQueue[i]);
			}

			this.eventObjectsQueue = [];
		}
	}

	/**
	 * retrieves eventInfo.eventAction attribute from eventObject, if it exists
	 * @param {Object} eventObject_ - object to retrieve action parameter from
	 * @return {string} eventAction or empty string
	 * @private
	 */
	_getEventAction(eventObject_) {
		let action = '';

		if (
			eventObject_ &&
			eventObject_.eventInfo &&
			eventObject_.eventInfo.eventAction
		) {
			action = eventObject_.eventInfo.eventAction;
		}

		return action;
	}
}

/**
 * Singleton instance
 * @type {TrackingAnalyticsApi}
 * @private
 * @static
 */

const trackingAnalyticsApi = new TrackingAnalyticsApi();
export { trackingAnalyticsApi, TrackingAnalyticsApi };
