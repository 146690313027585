import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectLayerClose {
	constructor() {
		if (ClickEventObjectLayerClose._instance) {
			return ClickEventObjectLayerClose._instance;
		}

		this.getData = this.getData.bind(this);
		this.layerCloseSelectors = [];

		clickEventService.register(this.getData);

		ClickEventObjectLayerClose._instance = this;
	}

	/**
	 * register a selector to be recognized as layer close
	 * @param {string} eventCloseSelector_ - the selector to register as a layer close
	 * @returns {void}
	 */
	registerLayerCloseSelector(eventCloseSelector_) {
		this.layerCloseSelectors.push(eventCloseSelector_);
	}

	/**
	 * getData - collects the tracking data
	 * @param {JSON} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	async getData(eventData_) {
		const eventTarget = ClickEventHelperDom.getTarget(eventData_);

		if (!this._isLayerCloseLink(eventTarget)) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: 'layer_close_click',
			},
		};
	}

	/**
	 * checks whether an event target is a layer close link
	 * @param {Element} eventTarget_ - an html element
	 * @returns {boolean} - true if it is a layer close link / false if not
	 */
	_isLayerCloseLink(eventTarget_) {
		for (const layerCloseSelector of this.layerCloseSelectors) {
			if (
				eventTarget_ &&
				eventTarget_.closest(layerCloseSelector) === eventTarget_
			) {
				return true;
			}
		}
		return false;
	}
}

const clickEventObjectLayerClose = new ClickEventObjectLayerClose();
export { clickEventObjectLayerClose, ClickEventObjectLayerClose };
