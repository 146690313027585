import { productService } from './product-service';

class ProductObjectDefault {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ProductObjectDefault._instance) {
			return ProductObjectDefault._instance;
		}

		productService.register(ProductObjectDefault.getData, true);

		ProductObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Promise} promise - returns promise for the product array
	 */
	static async getData() {
		return [];
	}
}

/**
 * Singleton instance
 * @type {ProductObjectDefault}
 * @private
 * @static
 */

const productObjectDefault = new ProductObjectDefault();

export { productObjectDefault, ProductObjectDefault };
