import { utils } from '../../utils';

class CartService {
	constructor() {
		this.cartObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredCartObjects =
			this._getRegisteredCartObjects.bind(this);
	}

	/**
	 * register - allows cart objects to register with cart service
	 * @param {Function} getDataCallback_ - callback function of registering cart object
	 * @param {boolean} priority_ - priority of cart object
	 * @returns {void}
	 */
	register(getDataCallback_, priority_) {
		const position =
			this.cartObjectCallbacks.length === 0
				? 1
				: this.cartObjectCallbacks.length;

		if (priority_) {
			this.cartObjectCallbacks[0] = getDataCallback_;
		} else {
			this.cartObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows objects to unregister from cart service
	 * @param {Function} getDataCallback_ - callback function of cart object
	 * @returns {void}
	 */
	unregister(getDataCallback_) {
		const index = this.cartObjectCallbacks.indexOf(getDataCallback_);
		this.cartObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @returns {Object} returns the cart object as a promise
	 */
	async getData() {
		const cartObjects = await this._getRegisteredCartObjects();
		const mergedCartObject = utils.mergeObjects(...cartObjects);

		return mergedCartObject;
	}

	/**
	 * _getRegisteredCartObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of cart objects
	 */
	async _getRegisteredCartObjects() {
		let cartObjects = [];

		for (const callback in this.cartObjectCallbacks) {
			if (callback && this.cartObjectCallbacks[callback]) {
				const tempObject = await this.cartObjectCallbacks[callback]();
				cartObjects.push(tempObject);
			}
		}

		return cartObjects;
	}
}

const cartService = new CartService();
export { cartService, CartService };
