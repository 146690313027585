/* global SETUPS */
export default class PageObjectDefaultPageInfo {
	/**
	 * getPageID - get page id for tracking
	 * @param {string} href_ - current href
	 * @param {string} pathname_ - current pathname
	 * @returns {string} page id
	 */
	getPageID(href_, pathname_) {
		let pageID = pathname_;

		if (this._hasSubString(href_, 'jslayer=')) {
			pageID = this._getPageIdForJsLayer(pageID, href_);
		} else if (this._hasSubString(href_, 'layer=')) {
			pageID = this._getSubStringByMarker(href_, 'layer=');
		} else if (this._hasSubString(href_, 'page=')) {
			pageID = this._getSubStringByMarker(href_, 'page=');
		}

		pageID = this._removeTrailingHtmlString(pageID);

		return pageID;
	}

	/**
	 * _getPageIdForJsLayer - returns page ID part for a jsLayer URL
	 * @param {string} pageID_ - the pageID_ string
	 * @param {string} href_ - the href
	 * @returns {string} pageName - string without trailing '.html*'
	 */
	_getPageIdForJsLayer(pageID_, href_) {
		let jsLayerName = this._getSubStringByMarker(href_, 'jslayer=');
		let pageID = pageID_;

		if (this._hasSubString(href_, 'page=')) {
			pageID = this._getSubStringByMarker(href_, 'page=');
		}

		pageID = this._removeTrailingHtmlString(pageID);

		return pageID + '/' + jsLayerName;
	}

	/**
	 * getPageName - get the page name for tracking
	 * @param {Element} domElement_ - the domElement to check for (optional)
	 * @return {string} - the page name
	 */
	getPageName(domElement_) {
		let pageName = this._getDocumentTitle();

		if (domElement_ && domElement_.getElementById('nm-page-title')) {
			pageName = domElement_.getElementById('nm-page-title').innerText;
		}
		return pageName;
	}

	/*
	 * _getDocumentTitle - returns the title of the documents title tag
	 * @return {string} the title tag text
	 */
	_getDocumentTitle() {
		let title = '';

		if (
			document.getElementsByTagName('title') &&
			document.getElementsByTagName('title')[0]
		) {
			title = document.getElementsByTagName('title')[0].innerText;
		}

		return title;
	}

	/**
	 * getDestinationUrl - get the destination URL for tracking
	 * @return {string} - the location href
	 */
	getDestinationUrl() {
		return window.location.href;
	}

	/**
	 * getReferringUrl - get the destination URL for tracking
	 * @return {string} - the referring url
	 */
	getReferringUrl() {
		return document.referrer || '';
	}

	/**
	 * getSysEnv - get the system environment for tracking
	 * @param {string} hostname_ - the current hostname
	 * @returns {string} production by default, staging in case of hostname
	 * contains 'pre-' or 'test-' or 'local' or 'axs' or '127.0.0.1'
	 */
	getSysEnv(hostname_) {
		let systemEnvironment = 'production';

		if (
			this._hasSubString(hostname_, 'local') ||
			this._hasSubString(hostname_, 'aem') ||
			this._hasSubString(hostname_, 'nemo') ||
			this._hasSubString(hostname_, '127.0.0.1')
		) {
			systemEnvironment = 'development';
		} else if (
			this._hasSubString(hostname_, 'pre-') ||
			this._hasSubString(hostname_, 'test-')
		) {
			systemEnvironment = 'staging';
		}

		return systemEnvironment;
	}

	/**
	 * getBreadCrumbs - get the page hierarchy a.k.a breadcrumbs
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - breadcrumbs for currently viewed page
	 */
	getBreadCrumbs(href_, pathname_) {
		let breadCrumbs = [];
		let pageIdsRaw = this.getPageID(href_, pathname_);
		const offsetIndex = this._getOffsetIndexId(pageIdsRaw);
		let pageIDs = this.getPageID(href_, pathname_).split('/');

		pageIDs.forEach(function (breadCrumb, index) {
			if (index > offsetIndex && breadCrumb !== '') {
				breadCrumbs.push(breadCrumb);
			}
		});

		return breadCrumbs;
	}

	/**
	 * getMarket - get the market of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - market ID
	 */
	getMarket(href_, pathname_) {
		let market = '';

		if (this._hasLocalMarket()) {
			market = this._getLocalMarket();
		} else {
			market = this._getMarketFromPageID(href_, pathname_)
				? this._getMarketFromPageID(href_, pathname_)
				: market;
		}

		return market.toUpperCase();
	}

	/**
	 * check for local market settings in SETUPS
	 * @returns {boolean} localCountry exists / does not exist
	 */
	_hasLocalMarket() {
		return (
			this._hasSetupsValue('LocaleCountry') ||
			this._hasSetupsValue('nemo.locale.country')
		);
	}

	/**
	 * return for local market settings in SETUPS
	 * @returns {string} localMarket - market string
	 */
	_getLocalMarket() {
		let localMarket = '';

		if (this._hasSetupsValue('LocaleCountry')) {
			localMarket = this._getSetupsValue('LocaleCountry');
		} else if (this._hasSetupsValue('nemo.locale.country')) {
			localMarket = this._getSetupsValue('nemo.locale.country');
		}

		return localMarket;
	}

	/**
	 * _getMarketFromPageID - get the market of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - market
	 */
	_getMarketFromPageID(href_, pathname_) {
		let market = '';
		let pageIDs = this.getPageID(href_, pathname_).split('/');

		if (pageIDs && pageIDs[3]) {
			market = pageIDs[3];
		}

		return market;
	}

	/**
	 * getLanguage - get the language of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - language
	 */
	getLanguage(href_, pathname_) {
		let language = '';

		if (this._hasLocalLanguage()) {
			language = this._getLocalLanguage();
		} else {
			language = this._getLanguageFromPageID(href_, pathname_);
		}

		return language.toLowerCase();
	}

	/**
	 * check for local language settings in SETUPS
	 * @returns {boolean} localLanguage exists / does not exist
	 */
	_hasLocalLanguage() {
		return (
			this._hasSetupsValue('LocaleLanguage') ||
			this._hasSetupsValue('nemo.locale.language')
		);
	}

	_getOffsetIndexId(pageIdsRaw) {
		const regexCommonPatternSure =
			/^\/(\w|-){2,4}\/(brand|web|touch)\/(\w|-){2,}/gi;
		const regexCommonPatternShortened = /^\/(\w|-){2,4}\//gi;
		let offsetIndex = 0;
		if (regexCommonPatternSure.test(pageIdsRaw)) {
			offsetIndex = 3;
		} else if (regexCommonPatternShortened.test(pageIdsRaw)) {
			offsetIndex = 1;
		}

		return offsetIndex;
	}

	/**
	 * return for local language settings in SETUPS
	 * @returns {string} localLanguage - language string
	 */
	_getLocalLanguage() {
		let localLanguage = '';

		if (this._hasSetupsValue('LocaleLanguage')) {
			localLanguage = this._getSetupsValue('LocaleLanguage');
		} else if (this._hasSetupsValue('nemo.locale.language')) {
			localLanguage = this._getSetupsValue('nemo.locale.language');
		}

		return localLanguage;
	}

	/**
	 * _getLanguageFromPageID - get the language of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - language
	 */
	_getLanguageFromPageID(href_, pathname_) {
		let language = '';
		let pageIDs = this.getPageID(href_, pathname_).split('/');

		if (pageIDs && pageIDs[3]) {
			language = pageIDs[3];
		}

		return language.toLowerCase();
	}

	/**
	 * return for any item in SETUPS by name
	 * @param {string} setupAttribute_ - name of the SETUPS value
	 * @returns {boolean} true if attribute exists and is not empty string / false otherwise
	 */
	_hasSetupsValue(setupAttribute_) {
		let hasValue = false;

		if (
			SETUPS &&
			SETUPS.get(setupAttribute_) &&
			SETUPS.get(setupAttribute_) !== ''
		) {
			hasValue = true;
		}

		return hasValue;
	}

	/**
	 * return value for any item in SETUPS by name
	 * @param {string} setupAttribute_ - name of the SETUPS value
	 * @returns {string} value - SETUPS attribute value
	 */
	_getSetupsValue(setupAttribute_) {
		let value = '';

		if (
			SETUPS &&
			SETUPS.get(setupAttribute_) &&
			SETUPS.get(setupAttribute_) !== ''
		) {
			value = SETUPS.get(setupAttribute_);
		}

		return value;
	}

	/*
	 * _getSubStringByMarker - checks a string for a certain string occurence
	 * @param {string} searchString_ - the input string
	 * @param {string} needle_ - the string to search for
	 * @return {string} the substring
	 */
	_hasSubString(searchString_, needle_) {
		return searchString_.indexOf(needle_) !== -1;
	}

	/*
	 * _getSubStringByMarker - returns a string part after found marker
	 * @param {string} string_ - the input string
	 * @param {string} marker_ - the marker string
	 * @return string} the substring
	 */
	_getSubStringByMarker(string_, marker_) {
		if (!this._hasSubString(string_, marker_)) {
			return '';
		}

		return string_.substr(string_.indexOf(marker_) + marker_.length);
	}

	/**
	 * remove possible trailing '.html*' from string
	 * @param {string} string_ - the input string
	 * @returns {string} pageName - string without trailing '.html*'
	 */
	_removeTrailingHtmlString(string_) {
		let cleanString = string_;

		if (cleanString.indexOf('.html') > 0) {
			cleanString = cleanString.substr(0, cleanString.indexOf('.html'));
		}

		return cleanString;
	}
}
