import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectDefault {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectDefault._instance) {
			return ClickEventObjectDefault._instance;
		}

		clickEventService.register(ClickEventObjectDefault.getData, true);

		ClickEventObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @param {JSON} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getData(eventData_) {
		let linkObject = {
			attributes: {
				clickID: ClickEventHelperDom.getClickID(eventData_),
				componentName: eventData_.moduleName,
				currentURL: eventData_.currentURL,
				elementName: ClickEventHelperDom.getElementName(eventData_),
				label: ClickEventHelperDom.getLabel(eventData_),
				pos: '',
				targetURL: ClickEventHelperDom.getTargetUrl(eventData_),
				value: '',
			},
			eventInfo: {
				eventAction: 'internal_link',
				eventName: 'generic',
			},
		};

		return linkObject;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectDefault}
 * @private
 * @static
 */

const clickEventObjectDefault = new ClickEventObjectDefault();

export { clickEventObjectDefault, ClickEventObjectDefault };
