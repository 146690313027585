/* global SETUPS */
export default class PageObjectArticleAttributes {
	/**
	 * getArticleFormat - get article format
	 * @returns {string} article format - empty by default
	 */
	_getArticleFormat() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.format') &&
			SETUPS.get('article.format') !== ''
		) {
			return SETUPS.get('article.format');
		}
		// default
		return '';
	}

	/**
	 * getArticlePublicationDate - get article publication date
	 * @returns {string} article publication date - empty by default
	 */
	_getArticlePublicationDate() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.publicationDate') &&
			SETUPS.get('article.publicationDate') !== ''
		) {
			return SETUPS.get('article.publicationDate');
		}
		// default
		return '';
	}

	/**
	 * getArticleReadingTime - get article reading time
	 * @returns {string} article reading time - 0 by default
	 */
	_getArticleReadingTime() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.readingTime') &&
			SETUPS.get('article.readingTime') !== ''
		) {
			return SETUPS.get('article.readingTime');
		}
		// default
		return '0';
	}

	/**
	 * getArticleId - get article id
	 * @returns {string} article id - empty by default
	 */
	_getArticleId() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.id') &&
			SETUPS.get('article.id') !== ''
		) {
			return SETUPS.get('article.id');
		}
		// default
		return '';
	}

	/**
	 * getArticleContentTags - get article content tags
	 * @returns {string} article content tags - empty by default
	 */
	_getArticleContentTags() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.contentTags') &&
			SETUPS.get('article.contentTags') !== ''
		) {
			return SETUPS.get('article.contentTags').split(',');
		}
		// default
		return [];
	}

	/**
	 * getArticleCategory - get article category
	 * @returns {string} article category - empty by default
	 */
	_getArticleCategory() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.category') &&
			SETUPS.get('article.category') !== ''
		) {
			return SETUPS.get('article.category');
		}
		// default
		return '';
	}

	/**
	 * getArticleUpdateDate - get article update date
	 * @returns {string} article update date - empty by default
	 */
	_getArticleUpdateDate() {
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('article.updateDate') &&
			SETUPS.get('article.updateDate') !== ''
		) {
			return SETUPS.get('article.updateDate');
		}
		// default
		return '';
	}

	getArticleAttributes() {
		let attributesObject = {
			articleCategory: this._getArticleCategory(),
			articleContentTags: this._getArticleContentTags(),
			articleFormat: this._getArticleFormat(),
			articleID: this._getArticleId(),
			articlePublicationDate: this._getArticlePublicationDate(),
			articleReadingTime: this._getArticleReadingTime(),
			articleUpdateDate: this._getArticleUpdateDate(),
		};
		return attributesObject;
	}
}
