import { componentService } from './component-service';

/**
 * @typedef {Object} TeaserTrackingObject
 * @property {Object} attributes
 * @property {number} attributes.moduleNumber
 * @property {number} attributes.subNumber
 * @property {string} attributes.teaserTitle
 */
class ComponentObjectTeaser {
	/**
	 * registers to ComponentService
	 * @constructor singleton
	 */
	constructor() {
		if (ComponentObjectTeaser._instance) {
			return ComponentObjectTeaser._instance;
		}

		componentService.register(ComponentObjectTeaser.getData);

		ComponentObjectTeaser._instance = this;
	}

	/**
	 * Retrieves teaser tracking data
	 * @param {HTMLElement} element_ - the component element
	 * @param {number} index_ - the index of element_ in its NodeList
	 * @returns {Promise<Array<TeaserTrackingObject>>} - Promise that evaluates to teaser tracking data
	 */
	static async getData(element_, index_) {
		const res = [];

		if (element_ === null) {
			return res;
		}

		const teaserElements = element_.querySelectorAll('.nm-j-teaser-item');
		const teaserElementsLength = teaserElements.length;

		for (let i = 0; i < teaserElementsLength; i += 1) {
			const teaserElement = teaserElements[i];
			let title = '';

			if (teaserElement.querySelector('.nm-j-teaser-title')) {
				title = teaserElement
					.querySelector('.nm-j-teaser-title')
					.innerText.trim();
			} else {
				title = teaserElement.innerText.trim();
			}

			res.push({
				attributes: {
					subNumber: i,
					teaserTitle: title,
				},
				componentInfo: {
					componentID: index_ + '-' + i,
				},
			});
		}

		return res;
	}
}

/**
 * Singleton instance
 * @type {ComponentObjectTeaser}
 * @private
 * @static
 */

const componentObjectTeaser = new ComponentObjectTeaser();

export { componentObjectTeaser, ComponentObjectTeaser };
