import { cartService } from './cart-service';

class CartObjectDefault {
	/**
	 * register to CartService as prioritized object
	 * @constructor singleton
	 */
	constructor() {
		if (CartObjectDefault._instance) {
			return CartObjectDefault._instance;
		}

		cartService.register(this.getData, true);

		CartObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Object} - the default cart object
	 */
	async getData() {
		const cartObject = {};
		return cartObject;
	}
}

/**
 * Singleton instance
 * @type {CartObjectDefault}
 * @private
 * @static
 */

const cartObjectDefault = new CartObjectDefault();
export { cartObjectDefault, CartObjectDefault };
