import { ClickEventHelperDom } from './click-event-helper-dom';
import { clickEventService } from './click-event-service';

class ClickEventObjectTab {
	constructor() {
		if (ClickEventObjectTab._instance) {
			return ClickEventObjectTab._instance;
		}

		this.getData = this.getData.bind(this);
		this.tabSelectors = [];

		clickEventService.register(this.getData);

		ClickEventObjectTab._instance = this;
	}

	/**
	 * Registers a string selector that identifies a tab element
	 * @param {string} tabSelector_ - identifies a tab element
	 * @returns {void}
	 */
	registerTabSelector(tabSelector_) {
		this.tabSelectors.push(tabSelector_);
	}

	/**
	 * Collects tab tracking data
	 * @param {JSON} eventData_ - serialized event data
	 * @returns {Promise<{}> | Promise<{attributes: {elementName: 'tab'}}>} - tracking data
	 */
	async getData(eventData_) {
		const eventTarget = ClickEventHelperDom.getTarget(eventData_);

		if (!this._isTab(eventTarget)) {
			return {};
		}

		return {
			attributes: {
				elementName: 'tab',
			},
		};
	}

	/**
	 * Returns whether an event target is a tab element or not
	 * @param {DOMTokenList} eventTarget_ - an html element
	 * @returns {boolean} - true if it is a tab element / false if not
	 */
	_isTab(eventTarget_) {
		for (const tabSelector of this.tabSelectors) {
			if (
				eventTarget_ &&
				eventTarget_.closest(tabSelector) === eventTarget_
			) {
				return true;
			}
		}
		return false;
	}
}

const clickEventObjectTab = new ClickEventObjectTab();
export { clickEventObjectTab, ClickEventObjectTab };
