class ClickEventHelperDom {
	/**
	 * @param {Event} event_ - click event
	 * @returns {string} link label
	 */
	static getTargetUrl(event_) {
		if (!event_.currentTarget) {
			return '';
		}

		return event_.currentTarget.href ? event_.currentTarget.href : '';
	}

	/**
	 * @param {Event} event_ - click event
	 * @returns {string} link label
	 */
	static getLabel(event_) {
		if (!event_.currentTarget) {
			return '';
		}

		let label = event_.currentTarget.textContent
			? event_.currentTarget.textContent
			: '';

		return label.trim();
	}

	/**
	 * @param {Event} event_ - click event
	 * @returns {string} element id / classname
	 */
	static getClickID(event_) {
		if (!event_.currentTarget) {
			return '';
		}

		return event_.currentTarget.id ? event_.currentTarget.id : '';
	}

	/**
	 * Returns the tracking element name of eventData_.currentTarget.
	 *
	 * @param {Object} eventData_ - the serialized event data
	 * @param {HTMLElement} eventData_.currentTarget - the element with event listener attached
	 * @param {string} [eventData_.currentURL] - the current location
	 * @param {string} [eventData_.moduleName] - the module's name
	 * @param {HTMLElement} eventData_.target - clicked element, that triggered the event
	 * @returns {"icon" | "image" | "button" | "text link" | "other" } tracking element name
	 */
	static getElementName(eventData_) {
		const target = eventData_.target;
		const currentTarget = eventData_.currentTarget;

		let elementName = 'other'; // default value

		if (!(currentTarget instanceof HTMLElement)) {
			return elementName;
		}

		if (
			ClickEventHelperDom._isTextLink(target) ||
			ClickEventHelperDom._isTextLinkArrow(target)
		) {
			elementName = 'text link';
		} else if (ClickEventHelperDom._isButton(target)) {
			elementName = 'button';
		} else if (
			ClickEventHelperDom._isIcon(target) &&
			!ClickEventHelperDom._isTextLinkArrow(target) &&
			!ClickEventHelperDom._isButton(target)
		) {
			elementName = 'icon';
		} else if (ClickEventHelperDom._isImage(target)) {
			elementName = 'image';
		}

		return elementName;
	}

	/**
	 * get the classList of an event target
	 * @param {Event} event_ - the event object
	 * @returns {DOMTokenList} - the classList of the event target
	 */
	static getTargetClassList(event_) {
		if (
			!event_ ||
			!event_.currentTarget ||
			!event_.currentTarget.classList
		) {
			return 0;
		}

		return event_.currentTarget.classList;
	}

	/**
	 * get the target of an event
	 * @param {Event} event_ - the event object
	 * @returns {Element} - the event target
	 */
	static getTarget(event_) {
		if (!event_) {
			return 0;
		}

		return event_.currentTarget;
	}

	/**
	 * checks for occurence of link classes
	 * @param {HTMLElement} domElement_ - dom Element
	 * @returns {boolean} - true if link class has been found | false
	 */
	static _isTextLink(domElement_) {
		if (!domElement_.classList) {
			return false;
		}

		return (
			domElement_.classList.contains('audi-link-s') ||
			domElement_.classList.contains('audi-link-m') ||
			domElement_.classList.contains('audi-copy-s') ||
			domElement_.classList.contains('audi-copy-m') ||
			domElement_.classList.contains('audi-headline-order-1') ||
			domElement_.classList.contains('audi-headline-order-2') ||
			domElement_.classList.contains('audi-headline-order-3') ||
			(domElement_.parentElement !== null &&
				(domElement_.parentElement.classList.contains('audi-link-s') ||
					domElement_.parentElement.classList.contains(
						'audi-link-m',
					) ||
					domElement_.parentElement.classList.contains(
						'audi-copy-s',
					) ||
					domElement_.parentElement.classList.contains(
						'audi-copy-m',
					)))
		);
	}

	/**
	 * checks for occurence of button classes
	 * @param {HTMLElement} domElement_ - dom Element
	 * @returns {boolean} - true if button class has been found | false
	 */
	static _isButton(domElement_) {
		if (!domElement_.classList) {
			return false;
		}

		return (
			domElement_.classList.contains('audi-button') ||
			(domElement_.parentElement !== null &&
				(domElement_.parentElement.classList.contains('audi-button') ||
					domElement_.parentElement.classList.contains(
						'audi-button__icon',
					)))
		);
	}

	/**
	 * checks if element is an icon
	 * @param {HTMLElement} domElement_ - dom Element
	 * @returns {boolean} - true if element is an icon | false
	 */
	static _isIcon(domElement_) {
		if (!domElement_.classList || !domElement_.tagName) {
			// we assume it's an svg, if one of those attributes don't exist #IE11
			return true;
		}

		return (
			domElement_.tagName.toLowerCase() === 'svg' ||
			(domElement_.parentElement !== null &&
				domElement_.parentElement.tagName.toLowerCase() === 'svg')
		);
	}

	/**
	 * checks if element is an image
	 * @param {HTMLElement} domElement_ - dom Element
	 * @returns {boolean} - true if element is an image | false
	 */
	static _isImage(domElement_) {
		if (!domElement_.tagName) {
			return false;
		}

		return (
			domElement_.tagName.toLowerCase() === 'img' ||
			domElement_.tagName.toLowerCase() === 'picture'
		);
	}

	/**
	 * checks if icon is an arrow within a text link
	 * @param {HTMLElement} domElement_ - dom Element
	 * @returns {boolean} - true if icon is an arrow | false
	 */
	static _isTextLinkArrow(domElement_) {
		return (
			ClickEventHelperDom._isIcon(domElement_) &&
			(domElement_.closest('.audi-link-m--arrow') ||
				domElement_.closest('.audi-link-s--arrow'))
		);
	}
}

export { ClickEventHelperDom };
