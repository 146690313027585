import { dealerService } from './dealer-service';

class DealerObjectDefault {
	/**
	 * registers to DealerService
	 * @constructor singleton
	 */
	constructor() {
		if (DealerObjectDefault._instance) {
			return DealerObjectDefault._instance;
		}

		dealerService.register(DealerObjectDefault.getData, true);

		DealerObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Promise} promise - returns promise for the dealer array
	 */
	static async getData() {
		return {};
	}
}

/**
 * Singleton instance
 * @type {DealerObjectDefault}
 * @private
 * @static
 */

const dealerObjectDefault = new DealerObjectDefault();

export { dealerObjectDefault, DealerObjectDefault };
