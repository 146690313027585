/* global SETUPS */
export default class PageObjectDefaultAttributes {
	/**
	 * getApplicationName - get application name
	 * @returns {string} application name - cms by default
	 */
	getApplicationName() {
		// check for page specific overwrites first
		if (document) {
			const applicationNameElem = document.querySelector(
				'meta[name=applicationName]',
			);
			if (applicationNameElem) {
				const applicationName =
					applicationNameElem.getAttribute('content');
				if (applicationName && applicationName != '') {
					return applicationName;
				}
			}
		}

		// KONG-461: Legal | oneShop Tracking | Adapt datalayer applicationName on /shop urls
		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('page.template') &&
			SETUPS.get('page.template') !== ''
		) {
			let pageTemplate = SETUPS.get('page.template');
			switch (pageTemplate) {
				case 'oneshop-page':
					return 'oneshop';
				case 'nextgen-configurator-page':
					return 'nemo';
				case 'nextgen-configurator-summary':
					return 'nemo';
				case 'myaudipage':
					return 'myaudi 3.0-main';
				case 'myaudi-emptypage':
					return 'myaudi 3.0-main';
				case 'focus-page':
					return 'myaudi 3.0-main';
				default:
					return 'cms';
			}
		}
		// default
		return 'cms';
	}

	/**
	 * getApplicationVersion - get application version
	 * @returns {string} application version
	 */
	getApplicationVersion() {
		let applicationVersion = '';
		let versions = document.getElementsByClassName('application-version');

		if (versions.length > 0) {
			applicationVersion = versions[0].getAttribute('data-version') || '';
		} else if (
			document.querySelectorAll('.nm-version-info dd') &&
			document.querySelectorAll('.nm-version-info dd').length > 0
		) {
			applicationVersion = document.querySelectorAll(
				'.nm-version-info dd',
			)[1].innerHTML;
		}

		return applicationVersion;
	}

	/**
	 * getImplementer - get agency id
	 * @returns {Number} agency id
	 */
	getImplementer() {
		return 1;
	}

	/**
	 * getSite - get site name
	 * @returns {string} site name
	 */
	getSite() {
		// check for page specific overwrites first
		if (document) {
			const siteElem = document.querySelector('meta[name=site]');
			if (siteElem) {
				const siteName = siteElem.getAttribute('content');
				if (siteName && siteName != '') {
					return siteName;
				}
			}
		}

		if (
			typeof SETUPS != 'undefined' &&
			SETUPS.get('page.template') &&
			SETUPS.get('page.template') !== ''
		) {
			let pageTemplate = SETUPS.get('page.template');
			switch (pageTemplate) {
				case 'myaudipage':
					return 'myaudi 3.0';
				case 'myaudi-emptypage':
					return 'myaudi 3.0';
				case 'focus-page':
					return 'myaudi 3.0';
				default:
					return 'product portal';
			}
		}
		return 'product portal';
	}
}
