import { utils } from '../../utils';

/**
 * Manages the retrieval of component tracking data from the DOM. Therefore, it iterates all components on a page.
 * For each component, calls registered callbacks, which add attributes to the component tracking object.
 */
class ComponentService {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (ComponentService._instance) {
			return ComponentService._instance;
		}

		this.registeredCallbacks = [];

		ComponentService._instance = this;
	}

	/**
	 * Adds callback_ to an internal array, that starts with index 1.
	 * If priority_ is true, callback_ is stored at index 0.
	 * So when it comes to merging the data returned by every registered callback, the priority one goes first.
	 * @param {Function} callback_ - adds data to the component tracking by returning them as Object or Array
	 * @param {boolean} priority_ - indicates the default callback
	 * @returns {void}
	 */
	register(callback_, priority_ = false) {
		if (typeof callback_ !== 'function') {
			throw new Error('bad parameter: callback_ is not of type function');
		}

		const index =
			this.registeredCallbacks.length === 0
				? 1
				: this.registeredCallbacks.length;

		if (priority_) {
			this.registeredCallbacks[0] = callback_;
		} else {
			this.registeredCallbacks[index] = callback_;
		}
	}

	/**
	 * The counterpart to register().
	 * @param {Function} callback_ - adds data to the component tracking by returning them as Object or Array
	 * @returns {void}
	 */
	unregister(callback_) {
		let index = this.registeredCallbacks.indexOf(callback_);

		if (index !== -1) {
			this.registeredCallbacks.splice(index, 1);
		}
	}

	ensureKnownChildFaLoadedInComponentArray(
		newComponentList,
		existingDigitalDataCompArray,
	) {
		const dependancyMap = new Map();

		// for my-audi templates where the fa is loaded with id profile-completion-loader
		dependancyMap.set('profile-completion-loader', [
			'fa-profile-completion',
			'fa-marketing-consents-registration',
		]);

		// for US & Canada where they manually put the fa-myaudi-initial-loader on the page
		dependancyMap.set('fa-myaudi-initial-loader', [
			'fa-profile-completion',
			'fa-marketing-consents-registration',
		]);

		if (
			existingDigitalDataCompArray &&
			typeof existingDigitalDataCompArray !== 'undefined'
		) {
			for (
				let componentIdx = 0;
				componentIdx < existingDigitalDataCompArray.length;
				componentIdx++
			) {
				if (
					typeof existingDigitalDataCompArray[componentIdx]
						.attributes == 'undefined'
				) {
					newComponentList.push(
						existingDigitalDataCompArray[componentIdx],
					);
				}
			}

			// for known fa: if parent fa is in component array also load the known child fa
			for (const key of dependancyMap.keys()) {
				//Search for nested components based on id or name depending on the situation
				//(myaudi page [componentId] vs manual placement of fa-myaudi-initial-loader[componentName])
				const parentFa =
					newComponentList.find(
						(x) => x?.componentInfo?.componentID == key,
					) ||
					newComponentList.find(
						(x) => x?.componentInfo?.componentName == key,
					);
				if (parentFa) {
					const childFaArr = dependancyMap.get(key);
					childFaArr.forEach((faIdentifier) => {
						const childFaComponent =
							existingDigitalDataCompArray.find(
								(x) =>
									x?.componentInfo?.componentID ==
									faIdentifier,
							);
						if (
							childFaComponent &&
							!newComponentList.find(
								(x) =>
									x.componentInfo?.componentID ==
									faIdentifier,
							)
						) {
							newComponentList.push(childFaComponent);
						}
					});
				}
			}
		}

		return newComponentList;
	}

	// preserve component array for components that are already on the page
	// this is needed for the in-page navigation where no real page reloads happen
	// otherwise the feature apps would be removed, which register before the classic AEM modules
	getComponentArray() {
		const newComponentList = [];
		const featureApps = document.querySelectorAll('feature-app');
		let featureAppsOnPage = Array.from(featureApps);
		let currentComponentList = window.digitalData.component;

		if (
			currentComponentList &&
			typeof currentComponentList !== 'undefined'
		) {
			// first add all feature apps that should be tracked in the order given by the current component list in digitalData
			for (
				let cmpIdx = 0;
				cmpIdx < currentComponentList.length;
				cmpIdx++
			) {
				if (
					currentComponentList[cmpIdx].componentInfo &&
					currentComponentList[cmpIdx].componentInfo.componentID
				) {
					const featureAppID =
						currentComponentList[cmpIdx].componentInfo.componentID;

					for (
						let appIdx = 0;
						appIdx < featureAppsOnPage.length;
						appIdx++
					) {
						const featureApp = featureAppsOnPage[appIdx];

						if (featureApp.id === featureAppID) {
							// do not add feature apps that have the data-audi-core-tracking-include attribute set to false
							const shouldBeTracked =
								!featureApp.hasAttribute(
									'data-audi-core-tracking-include',
								) ||
								featureApp.getAttribute(
									'data-audi-core-tracking-include',
								) === 'true';
							// do not add feature-app tags with the data-module attribute, because they will be added afterwards
							if (
								shouldBeTracked &&
								!featureApp.hasAttribute('data-module')
							) {
								newComponentList.push(
									currentComponentList[cmpIdx],
								);
								featureAppsOnPage.splice(appIdx, 1);
								break;
							}
						}
					}
				}
			}
		}

		// keep nested components - these are components that do not have attributes or category, only the componentInfo
		return this.ensureKnownChildFaLoadedInComponentArray(
			newComponentList,
			currentComponentList,
		);
	}

	/**
	 * Iterates all components on a page. For each component, awaits registered callbacks and adds the data of their
	 * evaluated Promises to the component tracking array.
	 * @returns {Promise<Array>} - Promise that evaluates to component tracking data
	 */
	async getData() {
		const componentElements = document.querySelectorAll('[data-module]');
		const componentElementsLength = componentElements.length;

		// KONG-242: Tracking | Feature Apps are removed from Component Array when navigating between Configurator Pages
		// Do not start with empty array in case of in-page navigation.
		// Keep those feature apps that are on the page already.
		let res = this.getComponentArray();
		for (
			let componentIndex = 0;
			componentIndex < componentElementsLength;
			componentIndex += 1
		) {
			const componentObjects = [];
			const componentCollections = [];

			for (let getComponentData of this.registeredCallbacks) {
				// note: typeof callback has been checked when registered
				const callbackResult = await getComponentData(
					componentElements[componentIndex],
					componentIndex,
				);

				if (
					callbackResult instanceof Array &&
					callbackResult.length > 0
				) {
					componentCollections.push(callbackResult);
				}
				// note: typeof Array is object as well
				else if (
					typeof callbackResult === 'object' &&
					callbackResult !== []
				) {
					componentObjects.push(callbackResult);
				}
			}

			if (componentCollections.length === 0) {
				res.push(utils.mergeObjects(...componentObjects));
			} else {
				res = res.concat(
					utils.flattenAndMergeObjects(
						componentCollections,
						componentObjects,
					),
				);
			}
		}

		return res;
	}
}

/**
 * Singleton instance
 * @type {ComponentService}
 * @private
 * @static
 */

const componentService = new ComponentService();

export { componentService, ComponentService };
