class PageService {
	constructor() {
		this.pageObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this._getRegisteredPageObjects =
			this._getRegisteredPageObjects.bind(this);
	}

	/**
	 * register - allows pageobjects to register with page-service
	 * @param {Function} getDataCallback_ - callback function of page object
	 * @param {boolean} priority_ - importance of page object
	 * @returns {void} returns nothing
	 */
	register(getDataCallback_, priority_) {
		let position =
			this.pageObjectCallbacks.length === 0
				? 1
				: this.pageObjectCallbacks.length;

		if (priority_) {
			this.pageObjectCallbacks[0] = getDataCallback_;
		} else {
			this.pageObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows pageobjects to unregister from page-service
	 * @param {Function} getDataCallback_ - callback function of page object
	 * @returns {void} returns nothing
	 */
	unregister(getDataCallback_) {
		let index = this.pageObjectCallbacks.indexOf(getDataCallback_);
		this.pageObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @returns {Object} returns the page object as a promise
	 */
	async getData() {
		let pageObjects = await this._getRegisteredPageObjects();

		let mergedPageObject = this._mergeObjects(...pageObjects);

		return mergedPageObject;
	}

	/**
	 * _getRegisteredPageObjects - collects the tracking data of all registered objects
	 * @returns {Array} returns array of pageobjects
	 */
	async _getRegisteredPageObjects() {
		let pageObjects = [];

		for (let callback in this.pageObjectCallbacks) {
			if (callback && this.pageObjectCallbacks[callback]) {
				let dataPageObject = await this.pageObjectCallbacks[callback]();
				pageObjects.push(dataPageObject);
			}
		}

		return pageObjects;
	}

	/**
	 * _mergeObjects - Performs a deep merge of objects and returns new object. Does not modify
	 * objects (immutable)
	 * @param {...object} objects - Objects to merge
	 * @returns {object} New object with merged key/values
	 */
	_mergeObjects(...objects) {
		const isObject = (obj) => obj && typeof obj === 'object';
		return objects.reduce((previousObject, currentObject) => {
			Object.keys(currentObject).forEach((key) => {
				const previousValue = previousObject[key];
				const currentValue = currentObject[key];

				if (
					Array.isArray(previousValue) &&
					Array.isArray(currentValue)
				) {
					previousObject[key] = currentValue;
				} else if (isObject(previousValue) && isObject(currentValue)) {
					previousObject[key] = this._mergeObjects(
						previousValue,
						currentValue,
					);
				} else {
					previousObject[key] = currentValue;
				}
			});

			return previousObject;
		}, {});
	}
}

const pageService = new PageService();
export { pageService, PageService };
