import { userService } from './user-service';

/**
 * @typedef {Object}UserTrackingObject
 * @property {Object} segment
 * @property {boolean} segment.isLoggedIn
 */
class UserObjectDefault {
	/**
	 * registers to UserService
	 * @constructor singleton
	 */
	constructor() {
		if (UserObjectDefault._instance) {
			return UserObjectDefault._instance;
		}

		userService.register(UserObjectDefault.getData, true);

		UserObjectDefault._instance = this;
	}

	/**
	 * Retrieves user tracking data
	 * @param {string} userId_ - the visitor id
	 * @returns {Promise<UserTrackingObject>} - Promise that evaluates to user tracking data
	 */
	static async getData() {
		const trackingSessionInStore = userService.getTrackingSessionStore();
		let isAuthenticated = false;
		if (trackingSessionInStore) {
			isAuthenticated = trackingSessionInStore.isAuthenticated;
		}
		const userObject = {
			segment: {
				isLoggedIn: isAuthenticated,
			},
		};

		return userObject;
	}
}

/**
 * Singleton instance
 * @type {UserObjectDefault}
 * @private
 * @static
 */

const userObjectDefault = new UserObjectDefault();
export { userObjectDefault, UserObjectDefault };
