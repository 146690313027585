import { searchService } from './search-service';

class SearchObjectDefault {
	/**
	 * register to CartService as prioritized object
	 * @constructor singleton
	 */
	constructor() {
		if (SearchObjectDefault._instance) {
			return SearchObjectDefault._instance;
		}

		searchService.register(this.getData, true);

		SearchObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Object} - the default search object
	 */
	async getData() {
		const searchObject = {};
		return searchObject;
	}
}

/**
 * Singleton instance
 * @type {SearchObjectDefault}
 * @private
 * @static
 */

const searchObjectDefault = new SearchObjectDefault();
export { searchObjectDefault, SearchObjectDefault };
