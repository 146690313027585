import { componentService } from './component-service';

/**
 * @typedef {Object} ComponentTrackingObject
 * @property {Object} componentInfo
 * @property {string} componentInfo.componentID
 * @property {string} componentInfo.componentName
 * @property {Object} category
 * @property {string} category.primaryCategory
 * @property {Object} attributes
 * @property {string} attributes.implementer
 * @property {string} attributes.version
 * @property {string} attributes.moduleNumber
 * @property {string} attributes.subNumber
 */
class ComponentObjectDefault {
	/**
	 * registers to ComponentService
	 * @constructor singleton
	 */
	constructor() {
		if (ComponentObjectDefault._instance) {
			return ComponentObjectDefault._instance;
		}

		componentService.register(ComponentObjectDefault.getData, true);

		ComponentObjectDefault._instance = this;
	}

	/**
	 * Retrieves component tracking data
	 * @param {HTMLElement} element_ - the component element
	 * @param {number} index_ - the index of element_ in its NodeList
	 * @returns {Promise<ComponentTrackingObject>} - Promise that evaluates to component tracking data
	 */
	static async getData(element_, index_) {
		if (element_ === null) {
			return null;
		}

		return {
			attributes: {
				implementer: 1, // TODO retrieve (for now hard coded) value from future data store
				moduleNumber: index_,
				subNumber: 0,
				version: '', // Can be left empty, as defined in spec
			},
			category: {
				primaryCategory: '', // TODO implement primaryCategory - Name of the related application
			},
			componentInfo: {
				componentID: index_ + '-0',
				componentName:
					ComponentObjectDefault.getComponentName(element_),
			},
		};
	}

	/**
	 * @param {Element} element_ - Dom Element
	 * @returns {string} component name
	 */
	static getComponentName(element_) {
		return element_.dataset.module ? element_.dataset.module : '';
	}
}

/**
 * Singleton instance
 * @type {ComponentObjectDefault}
 * @private
 * @static
 */

const componentObjectDefault = new ComponentObjectDefault();

export { componentObjectDefault, ComponentObjectDefault };
