import PageObjectArticleAttributes from './page-object-article-attributes';
import PageObjectDefaultAttributes from './page-object-default-attributes';
import PageObjectDefaultCategory from './page-object-default-category';
import PageObjectDefaultPageInfo from './page-object-default-pageinfo';
import { pageService } from './page-service';

class PageObjectDefault {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (PageObjectDefault._instance) {
			return PageObjectDefault._instance;
		}

		this.pagePageInfo = new PageObjectDefaultPageInfo();
		this.pageCategory = new PageObjectDefaultCategory();
		this.pageAttributes = new PageObjectDefaultAttributes();
		this.articleAttributes = new PageObjectArticleAttributes();

		pageService.register(PageObjectDefault.getData, true);

		PageObjectDefault._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @returns {Promise} promise - returns promise for pageObject
	 */
	static async getData() {
		let pageType =
			PageObjectDefault._instance.pageCategory.getPageType(document);

		let pageObject = {
			attributes: {
				applicationName:
					PageObjectDefault._instance.pageAttributes.getApplicationName(),
				applicationVersion:
					PageObjectDefault._instance.pageAttributes.getApplicationVersion(),
				implementer:
					PageObjectDefault._instance.pageAttributes.getImplementer(),
				site: PageObjectDefault._instance.pageAttributes.getSite(),
			},
			category: {
				displayType:
					PageObjectDefault._instance.pageCategory.getDisplayType(
						window.location.href,
					),
				pageType: pageType,
			},
			pageInfo: {
				breadCrumbs:
					PageObjectDefault._instance.pagePageInfo.getBreadCrumbs(
						window.location.href,
						window.location.pathname,
					),
				destinationURL:
					PageObjectDefault._instance.pagePageInfo.getDestinationUrl(),
				language: PageObjectDefault._instance.pagePageInfo.getLanguage(
					window.location.href,
					window.location.pathname,
				),
				market: PageObjectDefault._instance.pagePageInfo.getMarket(
					window.location.href,
					window.location.pathname,
				),
				pageID: PageObjectDefault._instance.pagePageInfo.getPageID(
					window.location.href,
					window.location.pathname,
				),
				pageName:
					PageObjectDefault._instance.pagePageInfo.getPageName(
						document,
					),
				referringURL:
					PageObjectDefault._instance.pagePageInfo.getReferringUrl(),
				sysEnv: PageObjectDefault._instance.pagePageInfo.getSysEnv(
					window.location.host,
				),
			},
		};

		if (pageType == 'articlePage') {
			Object.assign(
				pageObject.attributes,
				PageObjectDefault._instance.articleAttributes.getArticleAttributes(),
			);
		}

		return pageObject;
	}
}

/**
 * Singleton instance
 * @type {PageObjectDefault}
 * @private
 * @static
 */

const pageObjectDefault = new PageObjectDefault();

export { pageObjectDefault, PageObjectDefault };
