class ClickEventService {
	constructor() {
		this.eventObjectCallbacks = [];
		this.getData = this.getData.bind(this);
		this.clickSelectors = [
			'a',
			'.audi-button',
			'.audi-j-clicktracking-element',
		];
		this._getRegisteredEventObjects =
			this._getRegisteredEventObjects.bind(this);
	}

	/**
	 * register a new click selector
	 * @param {string} clickSelector_ - the click selector to register
	 * @returns {void}
	 */
	registerClickSelector(clickSelector_) {
		this.clickSelectors.push(clickSelector_);
	}

	/**
	 * register - allows click-eventobjects to register with click-event-service
	 * @param {Function} getDataCallback_ - callback function of click-event object
	 * @param {boolean} priority_ - importance of click-event object
	 * @returns {void} returns nothing
	 */
	register(getDataCallback_, priority_ = false) {
		let position =
			this.eventObjectCallbacks.length === 0
				? 1
				: this.eventObjectCallbacks.length;

		if (priority_) {
			this.eventObjectCallbacks[0] = getDataCallback_;
		} else {
			this.eventObjectCallbacks[position] = getDataCallback_;
		}
	}

	/**
	 * unregister - allows click-eventobjects to unregister from click-event-service
	 * @param {Function} getDataCallback_ - callback function of click-event object
	 * @returns {void} returns nothing
	 */
	unregister(getDataCallback_) {
		let index = this.eventObjectCallbacks.indexOf(getDataCallback_);
		this.eventObjectCallbacks.splice(index, 1);
	}

	/**
	 * getData - provides merged tracking data
	 * @param {Element} domElement_ - clicked element
	 * @param {String} currentLocation_ - current URL saved by click tracking
	 * @returns {Object} returns the click-event object as a promise
	 */
	async getData(domElement_, currentLocation_) {
		const firstMatchedElement =
			this._retrieveFirstMatchedElement(domElement_);
		const domElement = domElement_;

		if (!firstMatchedElement) {
			return {};
		}

		const eventData = this._serializeEventData(
			domElement,
			firstMatchedElement,
			currentLocation_,
		);
		const eventObjects = await this._getRegisteredEventObjects(eventData);
		const mergedObject = this._mergeObjects(...eventObjects);

		return mergedObject;
	}

	_retrieveFirstMatchedElement(domElement_) {
		let firstMatchedElement;

		if (domElement_) {
			for (const selector of this.clickSelectors) {
				firstMatchedElement = domElement_.closest(selector);

				if (firstMatchedElement) {
					return firstMatchedElement;
				}
			}
		}

		return false;
	}

	/**
	 * _getRegisteredEventObjects - collects the tracking data of all registered objects
	 * @param {JSON} eventData_ - event data
	 * @returns {Array} returns array of objects
	 */
	async _getRegisteredEventObjects(eventData_) {
		let eventObjects = [];

		for (let callback in this.eventObjectCallbacks) {
			if (callback && this.eventObjectCallbacks[callback]) {
				let dataEventObject = await this.eventObjectCallbacks[callback](
					eventData_,
				);
				eventObjects.push(dataEventObject);
			}
		}

		return eventObjects;
	}

	/**
	 * find module that the clicked link belongs to
	 * @param {Element} link_ - the DOM link to find the module for
	 * @return {string} - the module name or an emtpy string
	 */
	_getModule(link_) {
		let moduleElement = link_.closest('[data-module]');

		if (!moduleElement) {
			return '';
		}

		return moduleElement.getAttribute('data-module');
	}

	/**
	 * creates a JSON from event_ Object with relevant info
	 * @param {Element} clickedElement_ - element that triggered the event
	 * @param {Element} selectorElement_ - element that the event listener is attached to and matches a registered click selectors
	 * @param {String} currentLocation_ - current URL saved by click tracking
	 * @returns {Object} returns the Data Object
	 */
	_serializeEventData(clickedElement_, selectorElement_, currentLocation_) {
		let eventData = {
			currentTarget: selectorElement_,
			currentURL: currentLocation_,
			moduleName: this._getModule(selectorElement_),
			target: clickedElement_,
		};

		return eventData;
	}

	/**
	 * _mergeObjects - Performs a deep merge of objects and returns new object. Does not modify
	 * objects (immutable)
	 * @param {...object} objects - Objects to merge
	 * @returns {object} New object with merged key/values
	 */
	_mergeObjects(...objects) {
		const isObject = (obj) => obj && typeof obj === 'object';

		return objects.reduce((previousObject, currentObject) => {
			Object.keys(currentObject).forEach((key) => {
				const previousValue = previousObject[key];
				const currentValue = currentObject[key];

				if (
					Array.isArray(previousValue) &&
					Array.isArray(currentValue)
				) {
					previousObject[key] = currentValue;
				} else if (isObject(previousValue) && isObject(currentValue)) {
					previousObject[key] = this._mergeObjects(
						previousValue,
						currentValue,
					);
				} else {
					previousObject[key] = currentValue;
				}
			});

			return previousObject;
		}, {});
	}
}

const clickEventService = new ClickEventService();
export { clickEventService, ClickEventService };
